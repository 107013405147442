@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Open+Sans:wght@400;500&display=swap");

/* Thank You Section */
.thank-you-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #1a1a1a, #0d0d0d);
  color: #f5f5f5;
  font-family: "Open Sans", sans-serif; /* Body text font */
  text-align: center;
}

.container {
  border: 1px red solid;
  background-color: #222;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
  flex-direction: column;
  width: 100%;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #ff6b6b;
  font-family: "Poppins", sans-serif; /* Bold heading font */
}

p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #dcdcdc;
  margin-bottom: 2.5rem;
}

.btn-primary {
  background-color: #ff6b6b;
  color: #ffffff;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.3s;
  text-decoration: none;
  display: inline-block;
}

.btn-primary:hover {
  background-color: #ff9f43;
  transform: scale(1.05);
  box-shadow: 0 10px 25px rgba(255, 159, 67, 0.7);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 2rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .btn-primary {
    padding: 0.8rem 2rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .btn-primary {
    padding: 0.7rem 1.8rem;
    font-size: 0.9rem;
  }

  .btn-icon {
    margin-left: 0.3rem;
  }
}

@media (max-width: 360px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn-primary {
    padding: 0.6rem 1.5rem;
    font-size: 0.8rem;
  }

  .btn-icon {
    margin-left: 0.2rem;
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.6rem;
  }

  p {
    font-size: 0.8rem;
  }

  .btn-primary {
    padding: 0.5rem 1.3rem;
    font-size: 0.7rem;
  }

  .btn-icon {
    margin-left: 0.1rem;
  }
}
