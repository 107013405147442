@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Open+Sans:wght@400;500&display=swap");

/* About Section */
.about-section {
  padding: 8rem 2rem;
  background-color: #eeeeee;
  color: #464646;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Archivo", sans-serif;
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
  max-width: 1200px; /* Constrain the width for larger screens */
  margin: 0 auto;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  opacity: 0; /* Initial state hidden */
  transform: translateY(50px); /* Initial state moved down */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.about-container.animate {
  opacity: 1;
  transform: translateY(0);
  animation: none; /* Disable animation after first run */
}

.about-image-container.animate {
  animation: fadeIn 1.5s ease-out forwards; /* Ensure the image fades in */
}
.about-image-container {
  overflow: hidden;
  animation: fadeIn 1.5s ease-out;
}

.about-image {
  width: 80%;
  object-fit: cover;
}

.about-text {
  flex: 1;
  max-width: 600px; /* Limit text width for readability */
  animation: slideInFromRight 1.5s ease-out; /* Slide-in animation */
}
.about-text.animate {
  animation: slideInFromRight 1.5s ease-out forwards;
}

.about-title {
  font-size: 4rem;
  margin-bottom: 2rem;
  color: #464646;
  animation: slideInFromLeft 1.5s ease-out; /* Slide-in animation */
}
.about-title.animate {
  animation: slideInFromLeft 1.5s ease-out forwards;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: #464646;
  animation: slideInFromRight 1.5s ease-out; /* Slide-in animation */
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .about-title {
    font-size: 2.5rem;
    text-align: center;
  }

  .about-text {
    text-align: center;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .about-image-container {
    width: 180px;
    height: 180px;
  }

  .about-title {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  .about-text p {
    font-size: 1.1rem;
  }
}

/**
 * Animation Keyframes
 */
/* Add animation keyframes */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
