@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

/* Hero Section */
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  padding: 3rem 2rem; /* Added more top padding for mobile */
  background: linear-gradient(); /* Dark gradient background */
  color: #000; /* Light text for contrast */
  text-align: center;
  font-family: "Archivo", sans-serif;
}

.hero-content {
  max-width: 1000px;
  animation: fadeIn 2s ease-out; /* Fade-in animation */
  margin: 0 auto; /* Center horizontally */
}

.hero-title {
  font-family: "Archivo", sans-serif;
  font-size: 4rem;
  margin-bottom: 1.5rem;
  color: #464646; /* Pure white for the heading */
  letter-spacing: 2px;
  animation: slideInFromLeft 1.5s ease-out; /* Slide-in animation for the title */
  font-weight: 400;
}

.hero-description {
  font-size: 1rem;
  margin-bottom: 2.5rem;
  color: #777575; /* Softer gray for the paragraph text */
  line-height: 1.8;
  animation: fadeIn 2.5s ease-out; /* Slight delay for staggered animations */
  max-width: 700px;
  margin: 0 auto;
}

.hero-highlight {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2.5rem;
  animation: fadeIn 3s ease-out; /* Slight delay for staggered animations */
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border-radius: 50px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.hero-btn-primary {
  background-color: #516dac;
  border: 2px solid #516dac;
  color: #fff;
}

.hero-btn-primary:hover {
  background-color: #fff;
  color: #464646;
  transform: scale(1.05); /* Slight zoom on hover */
}

.hero-btn-secondary {
  background-color: #516dac;
  border: 2px solid #516dac;
  color: #fff;
}

.hero-btn-secondary:hover {
  background-color: #fff;
  color: #464646;
  transform: scale(1.05); /* Slight zoom on hover */
}

.btn-icon {
  margin-left: 0.5rem; /* Space between text and icon */
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }

  .hero-buttons {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 3.5rem 1rem; /* More top padding for mobile */
  }

  .hero-title {
    font-size: 2.8rem;
  }

  .hero-description {
    font-size: 1.1rem;
  }
}
