@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Open+Sans:wght@400;500&display=swap");

/* Header Styles */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: all 0.3s ease;
  font-family: "Archivo", sans-serif;
}

/* When hidden, move the header up off the screen */
.hide-nav {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  height: 6rem;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 1rem 0.1rem 1rem;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 0 0 1rem 1rem;
  background: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: none;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #5b8bfc; /* Primary theme color */
  text-decoration: none;
  margin-right: auto;
  padding: 2rem auto 2rem 2rem;
  margin-top: 2rem;
}

.logo a {
  font-size: 2.5rem;
  font-weight: bold;
  color: #516dac; /* Primary theme color */
  text-decoration: none;
}

.nav {
  display: flex;
  align-items: center;
  padding: 0;
  margin: -3rem 0.1rem 2rem auto;
  background-color: #fff;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-left: 1rem;
}

.nav-item a {
  font-family: "Archivo", sans-serif;
  font-size: 1rem;
  color: #464646;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-item a:hover {
  color: #516dac; /* Hover effect with secondary color */
  text-decoration: underline;
  text-underline-offset: 7px;
}

.toggle-lang {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-lang:hover {
  color: #ff9f43;
}

.menu-toggle {
  display: none;
  font-size: 2.5rem;
  cursor: pointer;
  color: #464646;
  transition: color 0.3s ease;
  margin: -3rem 1rem auto auto;
  z-index: 1100;
}

/* Responsive Styles */

/* Tablets and Medium Devices (up to 1024px) */
@media (max-width: 1024px) {
  .nav {
    position: absolute;
    top: 70%;
    left: 0;
    width: 100%;
    height: 100vh; /* Make the background cover the full viewport height */
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    z-index: 1000;
  }

  .nav-open {
    display: flex;
  }

  .nav-list {
    flex-direction: column;
  }

  .nav-item {
    margin: 1rem 0;
  }

  .menu-toggle {
    display: block;
    z-index: 1100;
  }

  .logo {
    font-size: 2rem;
    margin-top: 1rem;
  }
}

/* Mobile Devices (up to 768px) */
@media (max-width: 768px) {
  .nav-item a {
    font-size: 1.4rem;
    padding: 0.5rem 0.8rem;
  }

  .logo {
    font-size: 1.8rem;
    padding: 1rem auto 1rem 1rem;
  }

  .nav-item {
    margin-left: 1rem;
  }

  .menu-toggle {
    font-size: 2rem;
    margin: -2rem 1rem auto auto;
  }
}

/* Small Mobile Devices (up to 480px) */
@media (max-width: 480px) {
  .container {
    flex-direction: column;
    padding: 0.5rem;
  }

  .nav-item a {
    font-size: 1.2rem;
    padding: 0.4rem 0.6rem;
  }

  .logo {
    font-size: 1.5rem;
  }

  .menu-toggle {
    font-size: 1.8rem;
    margin: -2rem 0.5rem auto auto;
  }

  .nav {
    top: 60%; /* Adjust for smaller mobile screens */
    padding: 1rem 0;
  }
}

/* Large Desktop (up to 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .nav-item a {
    font-size: 1.8rem;
    padding: 0.5rem 1.2rem;
  }

  .logo {
    font-size: 2.8rem;
  }
}
