@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Open+Sans:wght@400;500&display=swap");

/* Skills Section */
.skills-section {
  padding: 8rem 2rem;
  background: linear-gradient(#fff); /* Dark gradient background */
  color: #464646; /* Light text for contrast */
  text-align: center;
  font-family: "Archivo", sans-serif;
}

.skills-title {
  font-size: 3.5rem;
  margin-bottom: 3rem;
  color: #464646; /* White for the heading */
  font-weight: 400;
  letter-spacing: 1.5px;

  animation: slideInFromLeft 1.5s ease-out; /* Slide-in animation */
}

.click-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2rem;
  animation: bounce 2s infinite;
}

.click-icon {
  color: #5b8bfc;
  transition: color 0.3s ease-in-out;
}

.click-icon-container p {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: #464646;
}

.click-icon-container:hover .click-icon {
  color: #ff9f43; /* Change color on hover */
}

.skills-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.skill-item {
  padding: 2rem;
  color: #464646;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  width: 250px;
  text-align: center;
  animation: fadeIn 2s ease-out; /* Fade-in animation */
}

.skill-icon {
  margin-bottom: 1rem;
  color: #464646;
  transition: color 0.3s ease-in-out;
}

.skill-item:hover .skill-icon {
  color: #ff9f43; /* Change color on hover */
}

.skill-name {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #464646;
}

.skill-level {
  width: 100%;
  background-color: #333;
  border-radius: 25px;
  overflow: hidden;
  height: 10px;
}

.level-bar {
  height: 100%;
  background-color: #5b8bfc; /* Primary theme color */
  border-radius: 25px;
  transition: width 0.3s ease-in-out;
}

/* Keyframes for bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Keyframes for fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .skills-section {
    padding: 6rem 2rem;
  }

  .skills-title {
    font-size: 2.5rem;
  }

  .skill-item {
    width: 200px;
  }
}
