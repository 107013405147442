@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Open+Sans:wght@400;500&display=swap");

/* src/assets/styles/components/_projects.css */

/* Projects Section */
.projects-section {
  padding: 8rem 2rem;
  background: linear-gradient(#fff); /* Deep dark gradient background */
  color: #464646; /* Slightly warmer light text for contrast */
  text-align: center;
  font-family: "Archivo", sans-serif;
}

.projects-title {
  font-size: 4rem;
  margin-bottom: 4rem;
  color: #464646;
  letter-spacing: 2px;
  animation: slideInFromLeft 1.2s ease-out;
  position: relative;
}

.projects-title::after {
  content: "";
  width: 80px;
  height: 4px;
  background-color: #5b8bfc;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

.projects-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.project-item {
  background: #fff;
  padding: 2rem;
  border-radius: 15px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  width: 100%;
  max-width: 350px; /* Adjust width to allow more content */
  text-align: left;
  animation: fadeIn 1.5s ease-out;
  overflow: hidden;
}

.project-item:hover {
  transform: translateY(-15px);
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.9);
}

.project-image,
.project-icon {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.project-item:hover .project-image,
.project-item:hover .project-icon {
  transform: scale(1.1);
}

.project-content {
  padding: 1rem 0;
}

.project-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #464646;
  font-weight: bold;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #464646;
  line-height: 1.7;
  max-height: 6.5rem; /* Increased max height */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Clamps the text to 4 lines */
  line-clamp: 4; /* Standard property for compatibility */
  -webkit-box-orient: vertical;
}

.project-btn-primary {
  background-color: #516dac;
  color: #ffffff;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-primary:hover {
  background-color: #ff9f43;
  transform: scale(1.1);
  box-shadow: 0 15px 45px rgba(255, 159, 67, 0.7);
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-60px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .projects-section {
    padding: 6rem 2rem;
  }

  .projects-title {
    font-size: 3rem;
  }

  .project-item {
    max-width: 100%;
  }

  .project-image,
  .project-icon {
    height: 200px;
  }
}
