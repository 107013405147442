@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Open+Sans:wght@400;500&display=swap");

/* Timeline Section Styling */
.timeline-section {
  padding: 6rem 2rem;
  background-color: #faf8f8;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-family: "Archivo", sans-serif;
}

/* Title Styling */
.timeline-title {
  font-size: 4rem;
  font-weight: 400;
  margin-bottom: 4rem;
  color: #464646;
}

/* Timeline Container */
.timeline {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  text-align: center; /* Center the text within the timeline container */
}
/*font <h1> */
.timeline h3 {
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 2rem;
  color: #464646;
}
/* font <h2> */
.timeline h2 {
  font-size: 2rem;
  font-weight: 200;
  margin-bottom: 1rem;
  color: #464646;
}
/*font <p> */
.timeline p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: #464646;
}

/* Central Timeline Line */
.timeline-line {
  position: absolute;
  left: 50%;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #516dac;
  transform: translateX(-50%);
  z-index: 1;
}

/* Timeline Items */
.timeline-item {
  position: relative;
  margin-bottom: 4rem;
  width: 45%; /* Limit width to 45% to create a balance */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  z-index: 2;
}

/* Alternating Left/Right for Timeline Items */
.timeline-item.left {
  left: 0;
  text-align: center;
  margin-right: auto;
}

.timeline-item.right {
  left: 0%;
  text-align: center;
  margin-left: auto;
}

/* Timeline Year Styling */
.timeline-year {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  border: 2px solid #516dac;
  z-index: 1;
}

/* Connector Line (Connecting Timeline Items to Central Line) */
.timeline-item::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50px;
  height: 2px;
  background-color: #516dac;
}

.timeline-item.left::after {
  right: -50px;
}

.timeline-item.right::after {
  left: -50px;
}

/* GitHub Button Styling */
.time-line-btn-primary {
  background-color: #516dac;
  color: #fff;
  padding: 0.6rem 1.2rem;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  margin-top: 1rem;
  display: inline-block;
}

.time-line-btn-primary:hover {
  background-color: #fff;
  color: #464646;
  border: #516dac 1px solid;
  transform: scale(1.05);
}

/* Course List Styling */
.course-list {
  margin-top: 1.5rem;
}

.course-list h4 {
  font-size: 1.2rem;
  font-weight: 100;
  color: #464646; /* Highlighted heading color */
  margin-bottom: 0.5rem;
}

.course-list strong {
  font-weight: 200;
  color: #464646; /* Highlighted text color */
}
.course-list ul {
  list-style-type: none;
  padding-left: 0;
}

.course-item {
  font-size: 1rem;
  font-weight: 100;
  color: #464646; /* Softer text color */
  margin-bottom: 0.5rem;
}

.course-description {
  display: block;
  margin-top: 0.2rem;
  margin-left: 1rem; /* Optional to indent descriptions */
  font-size: 0.9rem;
  color: #a5a5a5; /* Lighter color for descriptions */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .timeline-section {
    padding: 1rem 3.1rem 0 0;
  }
  .timeline-item {
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .timeline-item::after {
    display: none;
  }

  .timeline-item.left,
  .timeline-item.right {
    left: 0;
  }

  .timeline-line {
    left: 10px;
  }

  .timeline-year {
    left: 50px;
  }

  .timeline-item::before {
    left: 10px;
  }
}
