.footer {
  background-color: #1a1a1a;
  color: #ccc;
  padding: 2rem 0;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  border-top: 3px solid #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

.footer p {
  margin: 0;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #ddd;
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem;
  }

  .footer p {
    font-size: 0.9rem;
  }
}
