@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

/* Contact Section */
.contact-section {
  padding: 6rem 2rem;
  background-color: #516dac;
  color: #f0f0f0;
  text-align: center;
  font-family: "Archivo", sans-serif;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.contact-title {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
}

.contact-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
}

.contact-info {
  flex: 1;
  max-width: 400px;
  text-align: left;
}

.contact-icon {
  margin-right: 0.5rem;
  color: #fff;
}

.social-links {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.social-links a {
  color: #fff;
}

.contact-form {
  flex: 1;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  border: none;
  background-color: #fff;
  color: #333;
}

.contact-btn-primary {
  background-color: #fff;
  color: #464646;
  padding: 1rem 2rem;
  border-radius: 30px;
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
}

.contact-btn-primary:hover {
  background-color: #464646;
  color: #fff;
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    text-align: center;
    max-width: 100%;
  }

  .social-links {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 2.5rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 0.75rem;
    font-size: 1rem;
  }

  .btn-primary {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
}
